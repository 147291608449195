import React, { Fragment } from 'react';
import WaveBackGround from '../../../assets/images/scheduler-bg-2x.jpg';

const WaveLayOut = ({ children }) => {
  return (
    <Fragment>
      <img
        src={WaveBackGround}
        style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 1 }}
      />
      {children}
    </Fragment>
  );
};

export default WaveLayOut;
