'use strict';

/**
 * Returns x raised to the n-th power.
 *
 * @param {object} sitePlan plan site is subscribe to.
 * @param {object} user The power, must be a natural number.
 * @returns {boolean} canViewScheduler if user or site can access scheduler
 */
const canAccessSchedulerCheck = (sitePlan, user) => {
  let canViewScheduler = sitePlan?.planId === 1 ? false : true;
  if (user?.type !== 'other') {
    canViewScheduler = true;
  }
  return canViewScheduler;
};

export default canAccessSchedulerCheck;
