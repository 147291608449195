import React, { useEffect, useState } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Tab, Form, Tabs, Alert, Container, Modal } from 'react-bootstrap';

import { useStoreState, useStoreActions } from 'easy-peasy';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import styles from './LoginModal.module.css';
import { useFlags } from '@webfx/web-hooks';

export default function LoginModal({ onCloseModal }) {
  const url = useStoreState((state) => state.router.url);
  const { googleOauthUri } = useStoreState((state) => state.scheduler);
  const {
    onGetGoogleOuthLink,
    onConnectOutlookAccountWithNylas,
    onResetUserToken,
    onGetOffice365LoginRedirectLink,
  } = useStoreActions((actions) => actions.scheduler);
  const [isOutlookError, setIsOutlookError] = useState(false);
  const history = useHistory();
  const outlookFormik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().label('Email address').email().required('is required'),
      password: Yup.string().label('Password').required('is required'),
    }),
    onSubmit: async ({ email, password }, { setStatus, setFieldValue }) => {
      try {
        //await onResetUserToken({ email: email, password: password });
        const some = await onConnectOutlookAccountWithNylas({ email, password });
        history.push(url({ route: 'myInfo' }));
      } catch (err) {
        setIsOutlookError(true);
        setFieldValue('password', '', false);
      }
    },
  });

  const handleOffice365LoginRedirect = async () => {
    const { office365AuthRedirectUrl } = await onGetOffice365LoginRedirectLink();

    window.location.replace(office365AuthRedirectUrl);
  };

  const handleLogin = () => onGetGoogleOuthLink();
  useEffect(() => {
    if (googleOauthUri) {
      window.location.replace(googleOauthUri);
    }
  }, [googleOauthUri]);

  const flags = useFlags();

  return (
    <Modal show onHide={onCloseModal} backdrop="static" keyboard={true} centered>
      <Modal.Header closeButton>
        <Modal.Title>Log In to connect your calendar with SchedulerFX</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="text-center">
          <Tabs defaultActiveKey="google" id="oauth" className={styles.tabs}>
            <Tab eventKey="google" title="Google">
              <p>Click the button below to login using Google</p>
              <Button onClick={handleLogin}>Log In</Button>
            </Tab>
            <Tab eventKey="outlook" title="Outlook">
              <p>Login using your Outlook email and password.</p>

              <Form className={styles.form} onSubmit={outlookFormik.handleSubmit}>
                {isOutlookError && <Alert variant="danger">{'Invalid Sign-In'}</Alert>}

                <Form.Group controlId="outlookEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={outlookFormik.values.email}
                    onChange={outlookFormik.handleChange}
                    onBlur={outlookFormik.handleBlur}
                    disabled={outlookFormik.isSubmitting}
                  />
                </Form.Group>
                <Form.Group controlId="outlookPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={outlookFormik.values.password}
                    onChange={outlookFormik.handleChange}
                    onBlur={outlookFormik.handleBlur}
                    disabled={outlookFormik.isSubmitting}
                  />
                </Form.Group>

                <Button type="submit" disabled={outlookFormik.isSubmitting}>
                  Log In
                </Button>
              </Form>
            </Tab>

            {flags.schedulerOffice365 && (
              <Tab eventKey="office365" title="office365">
                <p>Login using your Office365 email</p>

                <Button onClick={handleOffice365LoginRedirect}>Log In</Button>
              </Tab>
            )}
          </Tabs>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
