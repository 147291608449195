import React, { useEffect } from 'react';

import { useStoreActions, useStoreState } from 'easy-peasy';
import { useAuth } from '@webfx/web-hooks';
import isUndefined from 'lodash-es/isUndefined';
import { Redirect } from 'react-router-dom';

import { Spinner } from '@webfx/core-web';

export default function LoginCallback() {
  const url = useStoreState((state) => state.router.url);
  const accessToken = useStoreState((state) => state.scheduler.accessToken);
  const error = useStoreState((state) => state.scheduler.error);
  const { onConnectGoogleAccountWithNylas, onConnectOffice365email } = useStoreActions(
    (actions) => actions.scheduler
  );

  const type = useAuth().user.type;

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const code = params.get('code');
  const state = params.get('state');
  const provider = params.get('provider');

  const redirectRoute = type === 'other' ? 'mcfxScheduler' : 'myInfo';
  const payload = {
    code,
    state,
    provider,
  };

  useEffect(() => {
    if (provider === 'office365') {
      onConnectOffice365email(payload);
    } else {
      onConnectGoogleAccountWithNylas(payload);
    }
  }, []);

  if (error) {
    return (
      <>
        <Redirect to={url({ route: redirectRoute })} />
      </>
    );
  }

  return (
    <>
      {isUndefined(accessToken) ? (
        <Spinner className="mt-5" />
      ) : (
        <Redirect to={url({ route: redirectRoute })} />
      )}
    </>
  );
}
