import React, { useEffect, useState } from 'react';
import { isUndefined } from 'lodash-es';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Spinner } from '@webfx/core-web';
import { useActiveSite } from '@webfx/web-hooks';
import { useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import LoginModal from '../LoginModal';
import schedulerConfig from '../../../config/schedulerConfig';

import useScheduler from './useScheduler';

const LoadingModal = () => {
  return (
    <Modal show backdrop="static" keyboard={true} centered>
      <Modal.Body>
        <Spinner />
      </Modal.Body>
    </Modal>
  );
};

export default function Schedules({ toggleShowSchedulerModal }) {
  const { nylasWebhookConfig, setScheduleScript } = useScheduler;
  const { accessToken, showLoginModal } = useStoreState((state) => state.scheduler);
  const { route } = useStoreState((state) => state.router);
  const { onVerifyUserIsAuthorized } = useStoreActions((actions) => actions.scheduler);
  const [scheduleScriptLoaded, scheduleScriptOnLoad] = useState(false);

  const currentPageRoute = useLocation();
  const site = useActiveSite({
    $join: ['company', 'plan'],
  });

  useEffect(() => {
    schedulerConfig.reminders = nylasWebhookConfig({
      siteId: site?.siteId,
      schedulerConfig,
    });

    onVerifyUserIsAuthorized();
  }, [site.siteId]);

  // Run Nylas script
  useEffect(() => {
    setScheduleScript({
      accessToken,
      scheduleScriptOnLoad,
    });
  }, [accessToken]);

  // only set company logo url on marketingcloudfx scheduler route
  const schedulerPath = route('mcfxScheduler').replace(':siteId?', site?.siteId);
  if (site?.siteId && currentPageRoute.pathname === schedulerPath && site?.company?.logoUrl) {
    schedulerConfig.appearance.logo = site?.company?.logoUrl;
  }

  // eslint-disable-next-line no-undef -- global nylas object
  if (!site.isLoading && !isUndefined(accessToken) && scheduleScriptLoaded && !isUndefined(nylas)) {
    // eslint-disable-next-line no-undef -- global nylas object
    nylas.scheduler.show({
      pageDomain: `${window.location.origin}/scheduler/book-meeting`,
      auth: {
        // Account access_token with active calendar scope
        accessToken,
      },
      style: {
        // Style the schedule editor
        tintColor: '#4C74B9',
        backgroundColor: 'white',
      },
      defaults: schedulerConfig,
    });
    //setting toggle to false because there is no nylas model close callback to set scheduler Manage Meeting Types nav state to false
    toggleShowSchedulerModal(false);
  }

  return (
    <>
      <LoadingModal />
      {showLoginModal && <LoginModal onCloseModal={toggleShowSchedulerModal} />}
      <div style={{ display: 'none' }}>no-op</div>
    </>
  );
}
