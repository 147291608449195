import React from 'react';

import { useStoreState } from 'easy-peasy';
import { Route, Switch } from 'react-router-dom';

const AuthLogin = React.lazy(() => './ui/pages/LoginModal');
import LandingPage from '@webfx/marketingcloud-web/src/ui/pages/Scheduler/LandingPage';
import LoginCallback from './ui/pages/LoginCallback';
const MeetWithMe = React.lazy(() => './ui/pages/MeetWithMe');
const BookingPage = React.lazy(() => './ui/pages/BookingPage');
import ThankYouPage from './ui/pages/ThankYouPage';
const RedirectPage = React.lazy(() => './ui/pages/RedirectPage');

import './store';

export default function App() {
  const { route } = useStoreState((state) => state.router);

  return (
    <Switch>
      <Route exact path={route('schedulerLandingPage')} component={LandingPage} />
      <Route exact path={route('schedulerLogin')} component={AuthLogin} />
      <Route exact path={route('schedulerLoginCallback')} component={LoginCallback} />
      <Route exact path={route('schedulerMeeting')} component={MeetWithMe} />
      <Route exact path={route('schedulerBook')} component={BookingPage} />
      <Route exact path={route('schedulerThankYouPage')} component={ThankYouPage} />
      <Route exact path={route('schedulerRedirectPage')} component={RedirectPage} />
    </Switch>
  );
}
