import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import { Spinner, Svg } from '@webfx/core-web';
import { useAuth } from '@webfx/web-hooks';

import Container from '../../components/Container';
import schedulerLogo from '../../../assets/images/schedulerLogo.svg';
import TopBanner from '../../components/TopBanner';
import SchedulerBanner from '../../components/SchedulerBanner';
import WaveLayOut from '../../layouts/WaveLayOut';
import styles from './BookingPage.module.css';
const thankYouPageUrl = 'https://www.webfx.com/lp/schedulerfx-contact-confirm.html';

/**
 *
 */
export default function BookingPage() {
  const { slug } = useParams();
  const { user } = useAuth();

  const [loading, setIsLoading] = useState(true);
  const toggleSetIsLoading = () => {
    setIsLoading(false);
  };

  const handleMessage = useCallback(
    (event) => {
      if (
        event &&
        event?.data?.message === 'Redirect' &&
        (event.origin.includes('webfx.com') || event.origin.includes('localhost'))
      ) {
        if (user) {
          window.location.replace('/scheduler/contact-confirm');
          return;
        }
        window.location.replace(thankYouPageUrl);
      }
    },
    [user]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  return (
    <WaveLayOut>
      <Container fill>
        <div className={`${styles.content} bg`}>
          <Row className={styles.header}>
            <Col xs={5} sm={3} className={styles.svgContainer}>
              <Svg src={schedulerLogo} alt="Scheduler logo" className={styles.schedulerLogo} />
            </Col>
            {/* Powered By section */}
            <Col xs={7} sm={9} className={styles.topBanner}>
              <TopBanner />
            </Col>
          </Row>
        </div>
        <div className={styles.wrapper}>
          {loading && <Spinner />}
          <iframe
            id="scheduler-iframe"
            src={`https://schedule.nylas.com/${slug}`}
            title="Scheduler"
            className={classnames(styles.iframe, { [styles.hidden]: loading })}
            onLoad={toggleSetIsLoading}
          />
        </div>
        <div className={styles.bottomBanner}>
          <SchedulerBanner />
        </div>
      </Container>
    </WaveLayOut>
  );
}
