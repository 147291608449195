const defaultConfig = {
  appearance: {
    color: '#4C74B9',
    company_name: 'WebFX',
    show_nylas_branding: false,
    submit_text: 'Book',
    thank_you_redirect: `${window.location.origin}/scheduler/redirect-page`,
    logo: `${window.location.origin}/assets/images/logos/webfx-dark@2x.png`,
  },
  booking: {
    min_buffer: 15,
    min_booking_notice: 1440,
    confirmation_method: 'automatic',
    confirmation_emails_to_host: true,
    confirmation_emails_to_guests: true,
    calendar_invite_to_guests: true,
    opening_hours: [
      {
        start: '09:00',
        end: '17:00',
        days: ['M', 'T', 'W', 'R', 'F'],
      },
    ],
    available_days_in_future: 14,
    min_cancellation_notice: 60,
    scheduling_method: 'round-robin-maximize-availability',
  },
  event: {
    duration: 15,
    location: 'Google Meet',
  },
  timezone: 'America/New_York',
  reminders: [
    {
      delivery_method: 'email',
      delivery_recipient: 'both',
      time_before_event: 60,
      email_subject: 'Reminder: You have a meeting!',
    },
  ],
};

export default defaultConfig;
