import React from 'react';
import Row from 'react-bootstrap/Row';

import marketingLogo from '../../../assets/images/marketinglogo.png';
import marketingLogoWhite from '../../../assets/images/marketinglogo_white.png';

import styles from './TopBanner.module.css';

export default function TopBanner() {
  return (
    <Row className={styles.topTag}>
      <div className={styles.leftSection}>
        <p>Powered By</p>
      </div>
      <div className={styles.arrowRight} />
      <div className={styles.marketingLogo}>
        <img
          src={marketingLogoWhite}
          alt="marketing-logo"
          className={`${styles.logoWhite} img-fluid`}
        />
        <img src={marketingLogo} alt="marketing-logo" className={`${styles.mktgLogo} img-fluid`} />
      </div>
    </Row>
  );
}
