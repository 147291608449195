import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from '@webfx/core-web';

import underline from '../../../assets/images/underline.png';
import schedulerCTA from '../../../assets/images/scheduer-fx-cta.png';

import styles from './SchedulerBanner.module.css';

export default function SchedulerBanner({ className }) {
  return (
    <div className={classnames(styles.footer, className)}>
      <Row className={styles.mainSection}>
        <img src={schedulerCTA} alt="laptop" className={styles.ctaImage} />
        <div className={styles.ctaText}>
          <Col className={styles.ctaSection}>
            <div className={styles.filler} />
            <div className={styles.footerText}>
              <div className={styles.relativeText}>
                Streamline your workflow. Get SchedulerFX for you and your company.
                <img src={underline} alt="underline" className={styles.underlineAbs} />
              </div>
            </div>
            <a href="https://www.webfx.com/lp/schedulerfx.html">
              <Button variant="primary" className={styles.actionsBtn}>
                Start Today&nbsp;
                <Icon>arrow_forward</Icon>
              </Button>
            </a>
          </Col>
        </div>
      </Row>
    </div>
  );
}

SchedulerBanner.propTypes = {
  className: PropTypes.string,
};

SchedulerBanner.defaultProps = {
  className: '',
};
