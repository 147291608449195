import { useEffect } from 'react';
import { createStore } from 'zustand-x';

import { useParams } from 'react-router-dom';
import { useAuth } from '@webfx/web-hooks';

import useProject from '../hooks/useProject';
import useTodolist from '../hooks/useTodolist';
import useMilestone from '../hooks/milestones/useMilestone';
import { useTodo } from '../hooks/todos/useTodo';
import useMessage from '../hooks/messages/useMessage';
export const store = createStore('teamwork')({
  projectId: null,
});

/**
 * Provides the context for the project.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The rendered component.
 */
export function ProjectProvider({ children }) {
  const { userId } = useAuth();
  const {
    projectId: projectIdParam = null,
    todolistId = null,
    todoId = null,
    milestoneId = null,
    messageId = null,
  } = useParams();

  const projectId = store.useTracked.projectId();

  const { data: todoData } = useTodo(todoId);
  const { data: todolistData } = useTodolist(todolistId);
  const { milestone: milestoneData } = useMilestone(milestoneId);
  const { message: messageData } = useMessage(messageId);
  useProject(projectId, true);

  useEffect(() => {
    if (!todoData && !todolistData && !milestoneData && !messageData) {
      return;
    }
    const data = todoData || todolistData || milestoneData || messageData;
    if (!data.projectId === projectId) {
      return;
    }
    store.set.projectId(data.projectId);
  }, [todoData, todolistData, milestoneData, messageData, projectId]);

  useEffect(() => {
    if (!userId) {
      return null;
    }
    const styleElement = document.createElement('style');
    const style = `.mention[data-id="${userId}"] { background-color: var(--color-dandelion) }`;

    styleElement.innerHTML = style;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [userId]);

  useEffect(() => {
    if (projectIdParam && Number(projectIdParam) !== projectId) {
      store.set.projectId(Number(projectIdParam));
    }
  }, [projectIdParam, projectId]);

  return <>{children}</>;
}
