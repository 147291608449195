import React, { Fragment } from 'react';
import { Icon, Svg } from '@webfx/core-web';
import { useDocumentTitle } from '@webfx/web-hooks';
import { Row, Col, Container } from 'react-bootstrap';

import SeoRevenueIcon from '../../../assets/images/seo-revenue.svg';
import PPCIcon from '../../../assets/images/ppc-managment.svg';
import WebsiteRedesignIcon from '../../../assets/images/website-redesign.svg';
import ManagementMarketingIcon from '../../../assets/images/management-marketing.svg';
import IndustryLeadingIcon from '../../../assets/images/industry-leading.svg';
import BusinessInMindIcon from '../../../assets/images/business-in-mind.svg';
import StreamWorkflowIcon from '../../../assets/images/stream-workflow.svg';
import schedulerLogo from '../../../assets/images/schedulerLogo.svg';
import marketingLogo from '../../../assets/images/marketingcloudfx.svg';
import crmLogo from '../../../assets/images/crmroi-dark.svg';
import styles from './ThankYouPage.module.css';

function Segments({ heading, subHeading, subHeadingLogo, content, linkText, linkSrc, sideIcon }) {
  return (
    <Fragment>
      <section className={styles.cardContainer}>
        <Row className="d-flex justify-content-center">
          <Col md={6} className={styles.cardColumn}>
            <div className="text-left">
              <h3 className={styles.cardHeading}>{heading}</h3>
              {subHeading && <h4 className={styles.cardSubHeading}>{subHeading}</h4>}
              {subHeadingLogo && <Svg src={subHeadingLogo} className={styles.subHeadingLogo} />}
              <div>
                <p className={styles.cardContent}>{content}</p>
              </div>
            </div>
            <div className={styles.cardLinkWrapper}>
              <div className={styles.cardRoundIconWrapper}>
                <Icon>arrow_forward</Icon>
              </div>
              <a className={styles.cardLinkText} href={linkSrc}>
                {linkText}
              </a>
            </div>
          </Col>
          <Col md={3} className={styles.cardColumn}>
            <Svg src={sideIcon} className={styles.bigIcon} />
          </Col>
        </Row>
      </section>
      <hr className={styles.cardDivider} />
    </Fragment>
  );
}

function ThankYouPage() {
  useDocumentTitle('SchedulerFX: Your Appointment Is Booked');
  const segments = [
    {
      key: 'seo-revenue',
      heading: 'DRIVE EARNING TRAFFIC:',
      subHeading: 'SEO & REVENUE GENERATION',
      subHeadingLogo: '',
      content: `Search engine optimization increases your bottom line by targeting customers that are more likely to
      convert. Our team will help improve your rankings and find keywords that are more likely to earn
      revenue at a return that keeps your business profitable.`,
      linkText: 'EARN QUALIFIED SEARCH TRAFFIC',
      linkSrc: 'https://www.webfx.com/seo-services.html',
      sideIcon: SeoRevenueIcon,
    },
    {
      key: 'make-sales-roar',
      heading: 'MAKE SALES SOAR:',
      subHeading: 'PPC MANAGEMENT SERVICES',
      subHeadingLogo: '',
      content: `Pay-per-click advertising allows you to reach the audience where they are already looking, putting your business in front of interested eyes. With over 650 campaigns managed, WebFX can help you increase conversions and drive more traffic to your site.`,
      linkText: 'TARGET QUALIFIED CUSTOMERS',
      linkSrc: 'https://www.webfx.com/ppc-management-services.html',
      sideIcon: PPCIcon,
    },
    {
      key: 'modernize-your-web',
      heading: 'MODERNIZE YOUR WEB PRESENCE:',
      subHeading: 'PROFESSIONAL WEBSITE REDESIGN SERVICES',
      subHeadingLogo: '',
      content: `Our website redesign experts want to improve your bottom line. A new site will bring in more traffic, drive more leads, and increase revenue. Our experts will create a beautiful, effective website to attract new customers to your business.`,
      linkText: 'BROWSE OUR WEB DESIGN PORTFOLIO',
      linkSrc: 'https://www.webfx.com/portfolio/',
      sideIcon: WebsiteRedesignIcon,
    },
    {
      key: 'analyze-grow',
      heading: 'ANALYZE & GROW YOUR PRESENCE:',
      subHeading: 'SOCIAL MEDIA MARKETING & MANAGEMENT SERVICES',
      subHeadingLogo: '',
      content: `WebFX experts build a customized social media marketing service plan to help your business build brand awareness and generate revenue from social media platforms. Our social media experts will build targeted advertising campaigns to drive leads and drive impactful growth.`,
      linkText: 'INCREASE YOUR SOCIAL REACH',
      linkSrc: 'https://www.webfx.com/Social-Media-Services.html',
      sideIcon: ManagementMarketingIcon,
    },
    {
      key: 'industry-leading',
      heading: 'INDUSTRY-LEADING TECHNOLOGY POWERING DIGITAL STRATEGY',
      subHeading: '',
      subHeadingLogo: marketingLogo,
      content: `WebFX's proprietary digital marketing application helps your team, track performance, monitor competitors, conduct industry research, calculate ROI, make strategic decisions to drive results for your business.
      With our AI powered by IBM Watson, we are able to monitor billions of data points to power strategic recommendations to stay ahead of digital marketing trends.`,
      linkText: 'TRACK RESULTS & EDGE OUT COMPETITION',
      linkSrc: 'https://www.webfx.com/marketingcloudfx.html',
      sideIcon: IndustryLeadingIcon,
    },
    {
      key: 'designed-with-your-mind',
      heading: 'DESIGNED WITH YOUR BUSINESS IN MIND',
      subHeading: '',
      subHeadingLogo: crmLogo,
      content: `Our CRM is designed with small-to-midsize businesses in mind. This affordable lead management solutions helps you track contact points with potential customers, determine what types of leads are driving sales for your business, and evaluate the results of your marketing efforts.`,
      linkText: 'START TRACKING LEADS TODAY',
      linkSrc: 'https://www.webfx.com/crmroi.html',
      sideIcon: BusinessInMindIcon,
    },
    {
      key: 'streamline-workflow',
      heading: 'STREAMLINE YOUR WORKFLOW',
      subHeading: '',
      subHeadingLogo: schedulerLogo,
      content: `Take control over your calendar and schedule meetings without the back-and-forth. Use our scheduling platform to set your team's availability and easily manage meetings with potential clients.`,
      linkText: 'SIMPLIFY THE MEETING PROCESS',
      linkSrc: 'https://www.webfx.com/lp/schedulerfx.html',
      sideIcon: StreamWorkflowIcon,
    },
  ];
  return (
    <Container fluid>
      <div className={styles.pageWrapper}>
        <h1 className={styles.ThankYouPageHeading}>Your meeting is booked!</h1>
        <h2 className={styles.ThankYouPageSubHeading}>We can&lsquo;t wait to talk with you.</h2>
        <Row className="d-flex justify-content-center">
          <Col md={4}>
            <p className={styles.ThankYouPageHeadingContent}>
              In the meantime, check out some of our other full-service web solutions, which help
              your business grow online leads, calls, and revenue. When you choose WebFX, you are
              choosing proven performance, transparent ROI tracking, an unparalleled track record,
              and to work with leading digital marketing experts.
            </p>
          </Col>
        </Row>
        <div className="segments-containers">
          {segments.map((segment) => (
            <Segments key={segment.key} {...segment} />
          ))}
        </div>
      </div>
    </Container>
  );
}

export default ThankYouPage;
