import React, { useEffect } from 'react';
import { Spinner } from '@webfx/core-web';

function RedirectPage() {
  useEffect(() => {
    if (parent) {
      if (location.search) {
        const params = new URLSearchParams(window.location.search);
        const data = { message: 'Redirect' };
        for (const [key, value] of params.entries()) {
          data[key] = value;
        }
        parent.postMessage(data, '*');
        return;
      }
      parent.postMessage('Redirect', '*');
    }
  }, []);

  return (
    <div style={{ background: '#fff', width: '100%', minHeight: '100vh' }}>
      <Spinner />
    </div>
  );
}

export default RedirectPage;
