import { store } from '@webfx/core-web';

store.dispatch.router.registerRoutes([
  { name: 'schedulerLogin', url: '/scheduler/login' },
  { name: 'schedulerLoginCallback', url: '/scheduler/authenticate' },
  { name: 'schedulerMeeting', url: '/scheduler/meet-with-me/:id' },
  { name: 'schedulerBook', url: '/scheduler/book-meeting/:slug' },
  { name: 'schedulerThankYouPage', url: '/scheduler/contact-confirm' },
  { name: 'schedulerRedirectPage', url: '/scheduler/redirect-page/' },
  { name: 'schedulerLandingPage', url: '/scheduler' },
]);
