import React from 'react';

import { useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Icon } from '@webfx/core-web';
import { Link } from 'react-router-dom';

import styles from './ScheduleCard.module.css';

export default function SchedulerCard({ title, subheader, slug }) {
  const url = useStoreState((state) => state.router.url);

  return (
    <Link to={url({ route: 'schedulerBook', slug })} className={styles.link}>
      <Card className={styles.meetingCard}>
        <Card.Header className={styles.colorTag} />
        <Card.Body>
          <Card.Title className={styles.cardTitle}>
            <div className={styles.meetingTitle}>
              <span className={styles.circle} />
              {title}
            </div>
            <Icon className="arrowRight">arrow_forward_ios</Icon>
          </Card.Title>
          <Card.Text className={styles.cardText}>{subheader}</Card.Text>
        </Card.Body>
      </Card>
    </Link>
  );
}

SchedulerCard.propTypes = {
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  slug: PropTypes.string.isRequired,
};

SchedulerCard.defaultProps = {
  subheader: '',
};
