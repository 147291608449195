import { isUndefined } from 'lodash-es';

const webhookData = {
  delivery_method: 'webhook',
  time_before_event: 20160,
  delivery_recipient: 'customer',
};

const setScheduleScript = ({ accessToken, scheduleScriptOnLoad }) => {
  if (isUndefined(accessToken)) {
    return null;
  }

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.src = 'https://schedule.nylas.com/schedule-editor/v1.0/schedule-editor.js';

  script.onload = () => scheduleScriptOnLoad(true);

  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
};

const nylasWebhookConfig = ({ schedulerConfig, siteId }) => {
  const webhooksUrls = process.env.POI_APP_SCHEDULER_WEBHOOK_URLS?.split(',');

  webhooksUrls.map((url) => {
    if (!url.includes('siteId') && siteId) {
      schedulerConfig.reminders.push({
        ...webhookData,
        webhook_url: `${url}?siteId=${siteId}`,
      });
    }
  });

  return schedulerConfig.reminders;
};

export default {
  webhookData,
  nylasWebhookConfig,
  setScheduleScript,
};
