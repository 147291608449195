import { feathers } from '@webfx/core-web';
import { store } from '@webfx/web-state';
import { thunk, action } from 'easy-peasy';
import { toast } from 'react-toastify';

const notificationConfig = {
  position: 'bottom-right',
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export default {
  accessToken: undefined,
  showLoginModal: false,
  googleOauthUri: undefined,
  schedulingPages: [],
  scheduleOwner: undefined,
  error: undefined,
  meetWithMeSlug: undefined,

  setAccessToken: action((state, payload) => {
    state.accessToken = payload;
  }),

  setDefaultConfiguration: action((state, payload) => {
    state.defaultConfiguration = payload;
  }),

  setShowLoginModal: action((state, payload) => {
    state.showLoginModal = payload;
  }),

  setGoogleOauthUri: action((state, payload) => {
    state.googleOauthUri = payload;
  }),

  setSchedulingPages: action((state, payload) => {
    state.schedulingPages = payload;
  }),

  setScheduleOwner: action((state, payload) => {
    state.scheduleOwner = payload;
  }),

  clearAccessToken: action((state) => {
    state.accessToken = undefined;
  }),

  setError: action((state, payload) => {
    state.error = payload;
  }),

  setMeetWithMeSlug: action((state, payload) => {
    state.meetWithMeSlug = payload;
  }),

  onVerifyUserIsAuthorized: thunk(async (actions, _) => {
    const user = store.auth.user();
    try {
      const { data } = await feathers()
        .service('user-settings')
        .find({
          query: {
            userId: user.userId,
            field: 'nylasAccessToken',
            $limit: 1,
          },
        });
      if (data !== undefined && data['0'] !== undefined) {
        let accessToken = data['0'];
        accessToken = accessToken.value;
        accessToken !== null && accessToken.length > 0
          ? actions.setAccessToken(accessToken)
          : actions.setShowLoginModal(true);
      } else {
        actions.setShowLoginModal(true);
      }
    } catch (error) {
      throw new Error(error);
    }
  }),

  onGetGoogleOuthLink: thunk(async (actions, _) => {
    const user = store.auth.user();
    const redirectUri = `${window.location.origin}/scheduler/authenticate`;
    try {
      const { googleOauthUri } = await feathers()
        .service('scheduler/auth')
        .find({
          query: {
            redirectUri,
            userId: user.userId,
          },
        });

      actions.setGoogleOauthUri(googleOauthUri);
    } catch (error) {
      throw new Error(error);
    }
  }),

  onResetUserToken: thunk(async () => {
    const user = store.auth.user();
    const { data } = await feathers()
      .service('user-settings')
      .find({
        query: {
          userId: user.userId,
          $limit: 1,
        },
      });
    if (data.length) {
      const userSettingId = data[0].userSettingId;
      await feathers().service('user-settings').patch(user.userId, {
        userSettingId,
        field: 'nylasAccessToken',
        value: '',
      });
    }
  }),

  onConnectGoogleAccountWithNylas: thunk(async (actions, payload) => {
    const user = store.auth.user();
    const { firstName, lastName } = user;
    const name = `${firstName} ${lastName}`;
    const redirectUri = `${window.location.origin}/scheduler/authenticate`;
    try {
      const { nylasAccessToken } = await feathers()
        .service('scheduler/auth')
        .create({
          ...payload,
          redirectUri,
          name,
          provider: 'gmail',
        });
      const { data } = await feathers()
        .service('user-settings')
        .find({
          query: {
            userId: user.userId,
            $limit: 1,
          },
        });
      if (data.length) {
        const userSettingId = data[0].userSettingId;
        await feathers().service('user-settings').patch(user.userId, {
          userSettingId,
          field: 'nylasAccessToken',
          value: nylasAccessToken,
        });
      }
      actions.setAccessToken(nylasAccessToken);
    } catch (error) {
      throw new Error(error);
    }
  }),

  onConnectOutlookAccountWithNylas: thunk(async (actions, payload) => {
    const user = store.auth.user();
    const { firstName, lastName } = user;
    const name = `${firstName}${lastName}`;
    const { email, password } = payload;

    try {
      const { nylasAccessToken } = await feathers().service('scheduler/auth').create({
        provider: 'outlook',
        email,
        password,
        name,
      });

      const { data } = await feathers()
        .service('user-settings')
        .find({
          query: {
            userId: user.userId,
            $limit: 1,
          },
        });
      if (data.length) {
        const userSettingId = data[0].userSettingId;
        await feathers().service('user-settings').patch(user.userId, {
          userSettingId,
          field: 'nylasAccessToken',
          value: nylasAccessToken,
        });
      }
      actions.setAccessToken(nylasAccessToken);
    } catch (error) {
      throw new Error(error);
    }
  }),

  onGetSchedulingPages: thunk(async (actions, payload) => {
    try {
      const { schedulingPages, ...owner } = await feathers()
        .service('scheduler/pages')
        .get(payload);
      actions.setSchedulingPages(schedulingPages);
      actions.setScheduleOwner(owner);
    } catch (error) {
      actions.setError(error);
    }
  }),

  onGetMeetWithMeSlug: thunk(async (actions, payload) => {
    try {
      const response = await feathers()
        .service('user-settings')
        .find({
          query: {
            userId: payload,
            field: 'meetWithMeSlug',
            $limit: 1,
          },
        });

      if (response.data && response.data.length > 0) {
        const { data } = response;
        const [value] = data;
        actions.setMeetWithMeSlug(value.value);
      }
    } catch (error) {
      actions.setError(error);
    }
  }),
  onGetOffice365LoginRedirectLink: thunk(async () => {
    const tenant = 'common';
    const client_id = process.env.POI_APP_AZURE_CLIENT_ID;
    const response_type = 'code';
    const redirect_uri = `${process.env.POI_APP_URL}/scheduler/authenticate?provider=office365`;
    const scope =
      'offline_access https://outlook.office365.com/EAS.AccessAsUser.All https://outlook.office365.com/EWS.AccessAsUser.All openid profile User.Read';
    const queryParams = `client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&response_type=${response_type}&prompt=consent`;
    const office365AuthRedirectUrl = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?${queryParams}`;
    return { office365AuthRedirectUrl };
  }),
  onConnectOffice365email: thunk(async (actions, payload) => {
    try {
      const user = store.auth.user();
      const { code } = payload;

      const { nylasAccessToken } = await feathers().service('scheduler/auth').create({
        provider: 'office365',
        code,
      });

      const { data } = await feathers()
        .service('user-settings')
        .find({
          query: {
            userId: user.userId,
            field: 'nylasAccessToken',
            $limit: 1,
          },
        });
      if (data.length) {
        const userSettingId = data[0].userSettingId;
        await feathers().service('user-settings').patch(user.userId, {
          userSettingId,
          field: 'nylasAccessToken',
          value: nylasAccessToken,
        });
      }
      actions.setAccessToken(nylasAccessToken);
      toast.success('office365 account connected', notificationConfig);
    } catch (error) {
      actions.setError(error.message);
      toast.error(error.message, notificationConfig);
    }
  }),
};
