import React, { useEffect } from 'react';

import { Avatar, Spinner, Svg } from '@webfx/core-web';
import { useAuth } from '@webfx/web-hooks';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { isUndefined } from 'lodash-es';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import schedulerLogo from '../../../assets/images/schedulerLogo.svg';
import Container from '../../components/Container';
import ScheduleCards from '../../components/ScheduleCard';
import SchedulerBanner from '../../components/SchedulerBanner';
import TopBanner from '../../components/TopBanner';
import WaveLayOut from '../../layouts/WaveLayOut';
import LoginModal from '../LoginModal';

import styles from './MeetWithMe.module.css';

export default function MeetWithMe() {
  const { scheduleOwner, schedulingPages, error } = useStoreState((state) => state.scheduler);
  const user = useAuth().user;
  const onGetSchedulingPages = useStoreActions((actions) => actions.scheduler.onGetSchedulingPages);
  const { id } = useParams();

  useEffect(() => {
    onGetSchedulingPages(id);
  }, [id]);

  return (
    <WaveLayOut>
      {error && user && <LoginModal onCloseModal={() => {}} />}
      <Container fill>
        <div className={`${styles.content} bg`}>
          <Row className={styles.header}>
            <Col xs={5} sm={3}>
              <Svg src={schedulerLogo} alt="Scheduler logo" className={styles.schedulerLogo} />
            </Col>
            {/* Powered By section */}
            <Col xs={7} sm={9} className={styles.topBanner}>
              <TopBanner />
            </Col>
          </Row>
          {/* main */}
          <div className={styles.schedulerContent}>
            {isUndefined(scheduleOwner) ? (
              <div className={styles.spinner}>
                <Spinner />
              </div>
            ) : (
              <>
                <Row className={styles.adminDetails}>
                  <Col>
                    <Avatar src={scheduleOwner.avatarUrl} alt="Avatar" className={styles.avatar} />
                    <h3 className={styles.adminName}>
                      {`${scheduleOwner.firstName} ${scheduleOwner.lastName}`}
                    </h3>
                    <p className={styles.subheader}>Pick an event to get started</p>
                  </Col>
                </Row>
                <Row className={styles.adminMeetings}>
                  {schedulingPages.map((page) => (
                    <Col xs={12} md={6} key={page.slug}>
                      <ScheduleCards title={page.title} slug={page.slug} />
                    </Col>
                  ))}
                </Row>
              </>
            )}
          </div>
        </div>
        <div className={styles.bottomBanner}>
          <SchedulerBanner />
        </div>
      </Container>
    </WaveLayOut>
  );
}
