import React from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Container as BootstrapContainer } from 'react-bootstrap/';

import styles from './Container.module.css';

export default function Container({ fill, className, children }) {
  return (
    <BootstrapContainer
      className={classnames(styles.wrap, { [styles.fill]: fill }, className)}
      fluid
    >
      {children}
    </BootstrapContainer>
  );
}

Container.propTypes = {
  fill: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

Container.defaultProps = {
  fill: false,
  className: '',
  children: undefined,
};
